import { safeGet, safeSet } from '../commonUtils'

/**
 * Take the instructions with the same insertion_order_id and create a List of Io Brief Object,
 * containing the value of the common field of the group of instructions.
 *
 * Need a DspConfig object
 *
 * Test available in test_insertionOrderProcessor.js
 */
class InsertionOrderProcessor {
  /**
   * @param instructions {CollectionInstruction<Instruction>}
   * @param dspConfig {DspConfig}
   * @return {import('../../types/instruction_type').IoBrief[]}
   */
  mapListIO (instructions, dspConfig) {
    const fields = this.generateField(dspConfig)
    return Object.keys(instructions).map(ioId => {
      let instructionsIdList = this.generateIdList(instructions[ioId])
      let groupNameList = this.generateGroupNameList(instructions[ioId])
      let filledField = fields.reduce((acc, field) => {
        let value = safeGet(instructions[ioId][0], field)
        safeSet(acc, field, value)
        return acc
      }, {})

      return {
        id: ioId,
        io: ioId,
        ...filledField,
        id_list: instructionsIdList,
        group_name_list: groupNameList
      }
    })
  }
  /**
   *
   * @param dspConfig {DspConfig}
   */
  generateField (dspConfig) {
    const toMerge = dspConfig.toMerge

    return toMerge.map((item) => {
      return item.prop.join('.')
    })
  }

  /**
   * @param instructionList {Instruction[]}
   * @returns {number[]}
   */
  generateIdList (instructionList) {
    return instructionList.map((item) => {
      return item.id
    })
  }

  /**
   * @param instructionList {Instruction[]}
   * @returns {string[]}
   */
  generateGroupNameList (instructionList) {
    return instructionList.map((item) => {
      return item.group_name
    })
  }

  /**
   * fill instructions with the value of ioBrief
   * @param instruction { Instruction } a instruction to fill with the brief
   * @param ioBrief {import('../../types/instruction_type').IoBrief} the brief
   * @param dspConfig { DspConfig }
   * @returns {*}
   */
  fillInstructionsWithIOBrief (instruction, ioBrief, dspConfig) {
    let ioField = dspConfig.ioField
    // New Io field t1074
    let newIoField = dspConfig.newIoField
    let toMerge = dspConfig.toMerge

    for (let i in toMerge) {
      this.fillFieldWithBrief(instruction, ioBrief, toMerge[i].prop)
    }

    instruction[ioField] = ioBrief.io
    instruction[newIoField] = ioBrief.io.toString()
    return instruction
  }

  /**
   * fill a field of the instruction with the same field in ioBrief.
   * The path is defined in pathToField
   * @param instruction {Instruction}
   * @param ioBrief {import('../../types/instruction_type').IoBrief}
   * @param pathToField {Array}
   */
  fillFieldWithBrief (instruction, ioBrief, pathToField) {
    let instruObject = instruction
    let briefObject = ioBrief
    let len = pathToField.length

    for (let j = 0; j < len - 1; j++) {
      let field = pathToField[j]

      if (briefObject[field] === undefined || briefObject[field] === null) {
        break
      }

      if ((typeof instruObject[field] === 'string' && instruObject[field].trim() === '') || !instruObject[field]) {
        instruObject[field] = {}
      }

      instruObject = instruObject[field]
      briefObject = briefObject[field]
    }

    // last item
    let lastField = pathToField[len - 1]

    if (briefObject[lastField] !== undefined && briefObject[lastField] !== null) {
      instruObject[lastField] = briefObject[lastField]
    } else {
      delete instruObject[lastField]
    }
  }
}

export {
  InsertionOrderProcessor
}
